import { $sentryClient } from '../store/client';

export function logSentryError(error: Error) {
  // if there's no client, re-throw the error to be caught normally
  if (!getSentryClient()) {
    throw error;
  }

  getSentryClient().captureException(error);
}

// attempts to run a function and any caught errors get sent
// to sentry
// eslint-disable-next-line @typescript-eslint/ban-types
export function tryWithSentry(fn: Function) {
  try {
    fn();
  } catch (error: any) {
    logSentryError(error);
  }
}

// returns an error that uses tryWithSentry above
// (use to generate safe callbacks)
// eslint-disable-next-line @typescript-eslint/ban-types
export function tryWithSentryFn(fn: Function) {
  return () => tryWithSentry(fn);
}

export const getSentryClient = () => $sentryClient.get();
