import { atom, computed } from 'nanostores';
import { initializeSegment } from '@nerdwallet/seo-analytics/segment';
import type { Event } from '@sentry/types';
import {
  Breadcrumbs,
  BrowserClient,
  Dedupe,
  GlobalHandlers,
  LinkedErrors,
  defaultStackParser,
  makeFetchTransport,
} from '@sentry/browser';
import config from '@nerdwallet/app-config';
import { setSentryClient } from '@nerdwallet/logging';
import { userAgentIntegration } from '../lib/sentry-integrations';

type OverlayStatus = 'animated' | 'visible' | 'hidden';

export const $showOverlay = atom<OverlayStatus>('hidden');

export const $openedMenu = atom<string>('');

export const $onCloseMenuCallback = atom<() => void>(null);

export const $segment = atom<ReturnType<typeof initializeSegment>>(
  initializeSegment()
);

export const $searchLoaded = atom<boolean>(false);
export const $authLoaded = atom<boolean>(false);
export const $overrideButtonAuthLoaded = atom<boolean>(false);

// This ensures that the fade-in of search and auth/override is coordinated when both are ready
export const $rightButtonsReady = computed(
  [$searchLoaded, $authLoaded, $overrideButtonAuthLoaded],
  (searchLoaded, authLoaded, overrideButtonAuthLoaded) =>
    searchLoaded && authLoaded && overrideButtonAuthLoaded
);

export const $sentryClient = atom<BrowserClient>(
  (function initSentryClient() {
    const sentryProps = {
      dsn: config?.COMPASS_SENTRY_DSN,
      environment: config?.COMPASS_SENTRY_ENV,
      release: config?._meta?.version ?? 'FIX_ME_NEEDS_VERSION_INFO',
      beforeSend: (event: Event) => {
        const updatedEvent = userAgentIntegration(event);
        return updatedEvent;
      },
      transport: makeFetchTransport,
      stackParser: defaultStackParser,
      integrations: [
        new Breadcrumbs(),
        new GlobalHandlers(),
        new LinkedErrors(),
        new Dedupe(),
      ],
    };

    if (!sentryProps.dsn || !sentryProps.environment) {
      // this ensures it won't try to re-initialize
      return null;
    }

    const SentryClient = new BrowserClient(sentryProps);
    setSentryClient(SentryClient);

    return SentryClient;
  })()
);
