/**
 * Some Sentry integrations do not work correctly for custom Sentry
 * instances like we are currently employing in window-markup. This is
 * documented in the following resources:
 *
 * - @see https://nerdwallet.atlassian.net/browse/WEL-100
 * - @see https://github.com/getsentry/sentry-javascript/issues/3887#issuecomment-954284944
 *
 * This issue _may_ be addressed in the upcoming Sentry v7 roadmap
 * {@link https://github.com/getsentry/sentry-javascript/issues/2817},
 * however in the meantime there is value in manually reproducing
 * functionality for some of these integrations.
 *
 * This file contains those custom integrations, which can then be run
 * on the `beforeSend` hook in our main sentry.ts file. Each
 * function's doc block should include a link to the core Sentry
 * integration it is meant to emulate.
 */

import { Event } from '@sentry/types';

/**
 * Sentry reference: https://github.com/getsentry/sentry-javascript/blob/115b0f3c07efa755c8f90d32cfd1783a35451eba/packages/browser/src/integrations/useragent.ts (with slight modifications)
 *
 * Append event details necessary to get browser data in Sentry.
 */
export const userAgentIntegration = (event: Event) => {
  /**
   * Only proceed if we are in the client and have a usable window object.
   *
   * Approximates
   * https://github.com/getsentry/sentry-javascript/blob/115b0f3c07efa755c8f90d32cfd1783a35451eba/packages/utils/src/global.ts#L36-L44
   */
  if (typeof window === 'undefined') {
    return event;
  }

  // grab as much info as exists and add it to the event
  const url = event?.request?.url || window?.location?.href;
  const { referrer } = window?.document || {};
  const { userAgent } = window?.navigator || {};

  const headers = {
    ...event?.request?.headers,
    ...(referrer && { Referer: referrer }),
    ...(userAgent && { 'User-Agent': userAgent }),
  };
  const request = { ...(url && { url }), headers };

  return { ...event, request };
};
